<template>
  <v-container
      fluid
      tag="section"
  >
    <v-row v-if="loading">
      <v-col>
        Loading...
      </v-col>
    </v-row>
    <template v-else>
      <v-row>
        <v-col >
          <v-card>
            <v-card-title>
              <span>Reference: {{ tracking.order.reference }}</span>
            </v-card-title>
          </v-card>
          <v-sheet class="mx-2 rounded-b px-10 py-5 shipment-summary">
            <v-row>
              <v-col cols="8">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td>Status: </td>
                      <td class="text-right">
                        <v-chip
                            label
                            :color="orderStatuses.find((item) => item.value === order.status).color"
                            class="summary-shipment-status px-10">
                          {{ orderStatuses.find((item) => item.value === order.status).title }}
                        </v-chip>
                      </td>
                    </tr>
                    <tr>
                      <td>Date: </td>
                      <td class="text-right">{{ order.ship_date | date('LL') }}</td>
                    </tr>
                    <tr>
                      <td>Tracking number: </td>
                      <td class="text-right">{{order.tracking_number}}</td>
                    </tr>
                    <tr>
                      <td>Customer reference: </td>
                      <td class="text-right">{{ order.customer_reference }}</td>
                    </tr>
                    <tr>
                      <td>Description: </td>
                      <td class="text-right">{{ order.shipment.description }}</td>
                    </tr>
                    <tr>
                      <td>Crated at: </td>
                      <td class="text-right">{{ order.created | date }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col>
                <v-img
                    :src="courierLogos[order.selected_rate.shipper_account.courier]"
                    height="96"
                    contain
                    class="mx-auto"/>
                <v-simple-table class="mx-10">
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td>Service: </td>
                      <td class="text-right">{{ order.selected_rate.service_name }}</td>
                    </tr>
                    <tr>
                      <td>Transit days: </td>
                      <td class="text-right">{{ order.selected_rate.transit_days }}</td>
                    </tr>
                    <tr>
                      <td>Discount: </td>
                      <td class="text-right">0 {{'GBP' | currency}}</td>
                    </tr>
                    <tr>
                      <td>Total cost: </td>
                      <td class="text-right">{{ order.selected_rate.total_cost }} {{ order.selected_rate.cost_currency | currency}}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-timeline align-top>
            <v-timeline-item
                v-for="(event, i) in events"
                :key="i"
                :color="event.color"
                icon="mdi-alert-circle"
                fill-dot
                large
            >
              <v-card class="pa-6">
                <v-chip
                    :color="event.color"
                    class="overline mb-3"
                    small
                >
                  {{ event.subtag_message }}
                </v-chip>

                <p
                    class="subtitle-1 font-weight-light"
                    v-text="event.message"
                />

                <p v-if="event.city">
                  {{event.country_name}} {{event.state}} {{event.city}} {{}}
                </p>

                <div class="text-uppercase body-2">
                  {{ event.checkpoint_time|date }}
                </div>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import TrackingEvent from "@/models/TrackingEvent";
import {courierLogos, orderStatuses} from "@/choices";

export default {
  data: () => ({
    loading: true,
    courierLogos,
    orderStatuses,
    timelines: [
      {
        chip: 'Some title',
        color: 'error',
        icon: 'mdi-briefcase',
        text: 'Wifey made the best Father\'s Day meal ever. So thankful so happy so blessed. Thank you for making my family We just had fun with the “future” theme !!! It was a fun night all together ... The always rude Kanye Show at 2am Sold Out Famous viewing @ Figueroa and 12th in downtown.',
        subtext: '11 hours ago via twitter',
      },
      {
        chip: 'Another one',
        color: 'success',
        icon: 'mdi-puzzle',
        text: 'Thank God for the support of my wife and real friends. I also wanted to point out that it’s the first album to go number 1 off of streaming!!! I love you Ellen and also my number one design rule of anything I do from shoes to music to homes is that Kim has to like it....',
      },
      {
        chip: 'Another title',
        color: 'info',
        icon: 'mdi-fingerprint',
        text: 'Called I Miss the Old Kanye That’s all it was Kanye And I love you like Kanye loves Kanye Famous viewing @ Figueroa and 12th in downtown LA 11:10PM. What if Kanye made a song about Kanye Royère doesn\'t make a Polar bear bed but the Polar bear couch is my favorite piece of furniture we own It wasn’t any Kanyes Set on his goals Kanye',
        action: 'info',
        actionIcon: 'mdi-wrench',
        actions: [
          'Action',
          'Another Action',
          'Something else here',
        ],
      },
      {
        chip: 'Another one',
        color: 'warning',
        icon: 'mdi-airplane-landing',
        text: 'Tune into Big Boy\'s 92.3 I\'m about to play the first single from Cruel Winter also to Kim’s hair and makeup Lorraine jewelry and the whole style squad at Balmain and the Yeezy team. Thank you Anna for the invite thank you to the whole Vogue team',
      },
    ],
  }),
  computed: {
    tracking() {
      return TrackingEvent.query().first();
    },
    order() {
      return this.tracking?.order;
    },
    events() {
      return this.tracking?.events;
    }
  },

  async created() {
    await TrackingEvent.api().get(`/api/v1/tracking/events/${this.$route.params.id}`);
    this.loading = false;
  }
}
</script>
